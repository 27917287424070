import React from 'react';
import {st, classes} from './Header.st.css';
import {useControllerProps} from '../Widget/ControllerContext';
import {StoreNameAndLogo} from '../../../common/components/StoreNameAndLogo/StoreNameAndLogo';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../../../common/constants';

export const Header = ({dataHook}: {dataHook: string}) => {
  const {
    paymentRequestSettingsStore: {paymentRequestSettings},
    navigationStore: {continueShoppingUrl},
    isOneColumnView,
  } = useControllerProps();
  const {experiments} = useExperiments();
  const shouldUseCheckoutHeaderOnCheckout = experiments.enabled(SPECS.UseCheckoutHeaderOnCheckout);
  const shouldUseUiFixForCheckoutHeader = experiments.enabled(SPECS.UiFixForCheckoutHeader);

  const headerClassName = () => {
    /* istanbul ignore else */
    /* istanbul ignore next */
    if (shouldUseCheckoutHeaderOnCheckout) {
      /* istanbul ignore next */
      if (shouldUseUiFixForCheckoutHeader) {
        return classes.headerOld;
      } /* istanbul ignore next */ else {
        return classes.header;
      }
    }
    return classes.headerOld;
  };

  const innerHeaderClassName = () => {
    /* istanbul ignore else */
    /* istanbul ignore next */
    if (shouldUseCheckoutHeaderOnCheckout) {
      /* istanbul ignore next */
      if (shouldUseUiFixForCheckoutHeader) {
        return classes.innerHeaderFix;
      } /* istanbul ignore next */ else {
        return classes.innerHeader;
      }
    }
    return classes.innerHeaderOld;
  };

  return (
    <header className={headerClassName()}>
      <div className={st(innerHeaderClassName())} data-hook={dataHook}>
        <StoreNameAndLogo
          continueShoppingUrl={continueShoppingUrl}
          logoUrl={paymentRequestSettings.logoUrl}
          storeName={paymentRequestSettings.storeName}
          checkoutHeader={paymentRequestSettings.checkoutHeader}
          isOneColumnView={isOneColumnView}
        />
      </div>
      <div className={classes.divider} />
    </header>
  );
};
